import React, { ReactNode } from 'react'
import slugify from 'slugify'
import { LocalizedString, Translate } from './i18n'

export type Project = {
  thumbnail: string
  title: string
  description: LocalizedString
  client?: {
    name: string
    url?: string
  }
  tags: string[]
  software?: string[]
  resources?: string[]
  scroll?: boolean
  hideTitle?: boolean
  content?: () => ReactNode | ReactNode[]
}

export const slug = (project: Project) =>
  slugify(project.title, { lower: true })

const projects: Project[] = [
  {
    thumbnail: 'MAIN_RK.gif',
    title: 'Animación banda RK',
    description: {
      es: 'Ilustración y animación para cabecera página',
      en: 'Ilustración y animación para cabecera página',
    },
    tags: ['Ilustración', 'Animación', 'After Effects'],
    resources: ['MAIN_RK.gif'],
    software: ['After Effects', 'Illustrator'],
  },
  {
    thumbnail: 'fundacionmogy.png',
    title: 'Web para Fundación Mogy',
    description: {
      es: 'Maquetación web',
      en: 'Maquetación web ',
    },
    content: () => (
      <>
        <Translate>Maquetación de web en Wordpress.</Translate>
      </>
    ),
    tags: ['Diseño gráfico', 'UX/UI', 'web'],
    resources: ['https://fundacionmogy.es/'],
    software: ['Wordpress', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    thumbnail: 'mogy.png',
    title: 'Web para Mogy',
    description: {
      es: 'Maquetación web',
      en: 'Maquetación web ',
    },
    content: () => (
      <>
        <Translate>Maquetación de web en Wordpress.</Translate>
      </>
    ),
    tags: ['Diseño gráfico', 'UX/UI', 'web'],
    resources: ['https://mogy.es/'],
    software: ['Wordpress', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    thumbnail: 'webrk2.jpg',
    title: 'Web para Redkoroko',
    description: {
      es: 'Diseño visual y maquetación web',
      en: 'Diseño visual y maquetación web ',
    },
    content: () => (
      <>
        <Translate>Diseño visual y maquetación de web en Wordpress.</Translate>
      </>
    ),
    tags: ['Diseño gráfico', 'UX/UI', 'web'],
    resources: ['https://marketingexternoredkoroko.es/'],
    software: ['Wordpress', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    thumbnail: 'sincronismo.png',
    title: 'Web para Sincronismo',
    description: {
      es: 'Maquetación web',
      en: 'Maquetación web ',
    },
    content: () => (
      <>
        <Translate>Maquetación de web en Wordpress.</Translate>
      </>
    ),
    tags: ['Diseño gráfico', 'UX/UI', 'web'],
    resources: ['https://sincronismo.com/'],
    software: ['Wordpress', 'CSS', 'JavaScript', 'PHP'],
  },
  {
    thumbnail: 'theperez.png',
    title: 'Web para The Pérez',
    description: {
      es: 'Diseño visual y maquetación web',
      en: 'Diseño visual y maquetación web ',
    },
    content: () => (
      <>
        <Translate>
          Diseño visual y maquetación con código html, sass y javascript.
        </Translate>
      </>
    ),
    tags: ['Diseño gráfico', 'UX/UI', 'web'],
    resources: [
      'https://theperez.es/',
      'Gatudataporatt-03.png',
      'Cuerpo_gatu-01.png',
    ],
    software: ['HTML', 'CSS', 'JavaScript'],
  },

  {
    thumbnail: 'Gatudataporatt-03.png',
    title: 'App Gatudata',
    description: {
      es: 'Proyecto final Ironhack | Nominado para el Hackshow | 2020',
      en: 'Ironhack final project | Selected for Hackshow | 2020',
    },
    tags: ['UX/UI'],
    scroll: true,
    software: ['Sketch', 'Adobe XD'],
    resources: [
      'Gatudataporatt-03.png',
      'Cuerpo_gatu-01.png',
      'https://vimeo.com/452510998',
    ],
    hideTitle: true,
    content: () => <></>,
  },

  {
    thumbnail: 'loading01.gif',
    title: 'Loading Gatudata',
    description: 'Animación',
    content: () => (
      <>
        <Translate>
          Animación de loading para app Gatudata. Puedes descargarla en
        </Translate>{' '}
        <a
          href="https://lottiefiles.com/32617-loading-gatudata"
          className="no-underline hover:underline text-teal-400"
        >
          <strong>Lottiefiles.</strong>
        </a>
      </>
    ),
    tags: ['Animación'],
    resources: [
      'https://assets5.lottiefiles.com/packages/lf20_uputri.json',
      'loading01.gif',
    ],
    software: ['Illustrator', 'After Effects', 'Lottiefiles'],
  },

  {
    thumbnail: 'Favor-pag-04.png',
    title: 'App Favor',
    description: {
      es: 'Caso de estudio',
      en: 'Case study',
    },
    tags: ['UX/UI'],
    scroll: true,
    resources: ['Gatudatacuerpo-03.png', 'https://vimeo.com/438249953'],
    software: ['Sketch', 'Adobe XD'],
    hideTitle: true,
    content: () => <></>,
  },
  {
    thumbnail: 'basketball_02.gif',
    title: 'Loading basketball',
    description: 'Animación',
    content: () => (
      <>
        <Translate>
          Animación de loading para basketball. Puedes descargarla en
        </Translate>{' '}
        <a
          href="https://lottiefiles.com/32960-loader-basketball"
          className="no-underline hover:underline text-teal-400"
        >
          <strong>Lottiefiles</strong>
        </a>
        .
      </>
    ),
    tags: ['Animación'],
    resources: [
      'https://assets6.lottiefiles.com/packages/lf20_oKu1nU.json',
      'basketball_02.gif',
    ],
    software: ['Illustrator', 'After Effects', 'Lottiefiles'],
  },
  {
    thumbnail: 'Planet_02.gif',
    title: 'Galaxy',
    description: {
      es: 'Ilustración con efecto parallax',
      en: 'Illustration with parallax effect ',
    },
    content: () => (
      <>
        <Translate>
          Ilustración con efecto parallax, pincha y muévela con el ratón.
          También puedes probar en el móvil y se moverá al ritmo de tú móvil.
        </Translate>
      </>
    ),
    tags: ['Diseño gráfico', 'Ilustración', 'UX/UI', 'web'],
    resources: ['https://noemi-vi.github.io/vertical_planet/'],
    software: ['Illustrator', 'After Effects', 'JavaScript'],
  },
  {
    thumbnail: 'HEART-min.gif',
    title: 'Heart',
    description: {
      es: 'Ilustración y animación realizada con Procreate (iPad)',
      en: 'Illustration and animation made with Procreate (iPad)',
    },
    tags: ['Ilustración', 'Animación'],
    resources: ['HEART-min.gif'],
    software: ['Ipad', 'Procreate'],
  },
  {
    thumbnail: 'Retrato_A.jpg',
    title: 'Retrato',
    description: {
      es: 'Ilustración realizada con Procreate (iPad)',
      en: 'Illustration made with Procreate (iPad)',
    },
    tags: ['Ilustración'],
    resources: ['Retrato_A.jpg'],
    software: ['Ipad', 'Procreate'],
  },
  {
    thumbnail: 'Tinder.png',
    title: 'Tinder',
    description: {
      es: 'Caso de estudio | Nueva funcionalidad',
      en: 'Case study | New functionality',
    },
    content: () => (
      <>
        <Translate>
          Proyecto en equipo, en el que consistía en el diseño de una nueva
          función para la app de Tinder. Al tener claro lo que buscan, necesitan
          una búsqueda más acotada. Las herramientas utilizadas son Lean UX
          Canvas, Mapa de empatía. Para más info accede a través de
        </Translate>{' '}
        <a
          href="https://medium.com/@Noemi.Vi/proyecto-3-tinder-78a6ffd290dd"
          className="no-underline hover:underline text-teal-400"
        >
          <strong>Medium.</strong>
        </a>
      </>
    ),
    tags: ['UX/UI'],
    resources: ['https://vimeo.com/433516929'],
    software: ['Sketch', 'Flinto'],
  },
  {
    thumbnail: 'captura-de-pantalla-2019-09-20-a-las-19.04.27.png',
    title: 'The Bestiary of Design',
    scroll: true,
    description: {
      es: 'Carta presentación interactiva',
      en: 'Interactive cover letter',
    },
    content: () => (
      <Translate>
        Proyecto personal que diseñé como carta de presentación interactiva que
        refleja mis habilidades en diseño, haciendo un guiño a los libros de
        bestiarios. Cada bestia es una rama del diseño y puedes combinarlas
        entre sí.
      </Translate>
    ),
    tags: ['Diseño gráfico', 'Ilustración', 'web'],
    resources: ['https://bestiary.design'],
    software: ['Illustrator', 'JavaScript'],
  },
  {
    thumbnail: 'portfolio.png',
    title: 'Ejercicio HTML y CSS',
    description: {
      es: 'Semana programación Ironhack',
      en: 'Ironhack programming week',
    },
    content: () => (
      <Translate>
        Ejercicio realizado con HTML y CSS para semana de programación de
        Ironhack.
      </Translate>
    ),
    tags: ['web'],
    resources: ['https://noemi-vi.github.io/portfolio/'],
    software: ['HTML', 'CSS', 'JavaScript'],
  },
  {
    thumbnail: 'bestia-avestruz.png',
    title: 'Las bestias',
    description: {
      es: 'Ilustración para proyecto bestiary',
      en: 'Illustration for bestiary project',
    },
    content: () => (
      <Translate>
        Ilustración de las bestias para proyecto personal Bestiary of Design.
      </Translate>
    ),
    tags: ['Diseño gráfico', 'Ilustración'],
    resources: [
      'bestia-avestruz.png',
      'bestia-guepardo.png',
      'bestia-zorro.png',
      'bestia-jirafa.png',
    ],
    software: ['Illustrator'],
  },
  {
    thumbnail: 'PACMAN.gif',
    title: 'Pacman',
    description: {
      es: 'Animación Game Boy',
      en: 'Game Boy animation',
    },
    content: () => (
      <Translate>
        Ejercicio de animación para curso en School Motion design.
      </Translate>
    ),
    tags: ['Animación'],
    software: ['Affter Effects'],
  },
  {
    thumbnail: 'esca.png',
    title: 'Escafandra',
    description: {
      es: 'Composición ilustrada realizada con antiguos grabados',
      en: 'Illustrated composition made with old engravings',
    },
    tags: ['Ilustración'],
    software: ['Photoshop'],
  },
  {
    thumbnail: 'watch.gif',
    title: 'Watch',
    description: {
      es: 'Microinteracción de menú desplegable para watch',
      en: 'Drop-down menu microinteraction for watch',
    },
    tags: ['Animación'],
    software: ['Affter Effects', 'Photoshop'],
  },
  {
    thumbnail: 'Shop.gif',
    title: 'Shop',
    description: {
      es: 'Ejercicio de animación para curso Motion Design Schoo.',
      en: 'Animation exercise for Motion Design School course',
    },
    tags: ['Animación'],
    software: ['Affter Effects', 'Photoshop'],
  },
  {
    thumbnail: 'enanitos.gif',
    title: 'Dwarfs in the forest',
    description: {
      es: 'Ejercicio de animación para curso Motion Design School',
      en: 'animation exercise for Motion Design School course',
    },
    content: () => (
      <Translate>
        Ejercicio de animación del curso Fundamentals course on After Effects by
        Motion Design School. Para realizar este ejercicio he usado expresiones,
        loop, parenting, texturas, fire rig freebie y script de motion tools.
      </Translate>
    ),
    tags: ['Animación'],
    software: ['Affter Effects', 'Photoshop'],
  },
  {
    thumbnail: 'kid.gif',
    title: 'Kid',
    description: {
      es: 'Ilustración realizada y animada con Procreate (iPad)',
      en: 'Illustration made and animated with Procreate (iPad)',
    },
    tags: ['Animación', 'Ilustración'],
    software: ['Ipad', 'Procreate'],
  },
  {
    thumbnail: 'moon.gif',
    title: 'Pink Moon',
    description: {
      es: 'Ilustración creada y animada con Procreate (iPad)',
      en: 'Illustration created and animated with Procreate (iPad)',
    },
    tags: ['Animación', 'Ilustración'],
    software: ['Ipad', 'Procreate'],
  },

  {
    thumbnail: 'Fox-02.jpg',
    title: 'Foxwood',
    description: {
      es: 'Ilustración',
      en: 'Illustration',
    },

    tags: ['Ilustración'],
    resources: ['Fox-02.jpg'],
    software: ['Ipad', 'Procreate'],
  },
  {
    thumbnail: 'jirafa.png',
    title: 'Jirafa',
    description: {
      es: 'Ilustración de Jirafa',
      en: 'Giraffe illustration',
    },

    tags: ['Ilustración'],
    resources: ['jirafa.png'],
    software: ['Ipad', 'Procreate'],
  },
  {
    thumbnail: 'watch.png',
    title: 'Propuesta Watch Active Samsung',

    description: 'Display',
    content: () => (
      <Translate>
        Propuesta de varios display retroiluminados para lineales en tienda del
        Watch Active Samsung.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'watch.png',
      'watch02.png',
      'watch04.png',
      'watch05.png',
      'watch06.png',
      'watch08.png',
      'watch09.png',
      'watch10.png',
    ],
    software: ['Sketchup', 'Layout'],
  },
  {
    thumbnail: 'Box-01.png',
    title: 'Diseño de packaging Samsung',

    description: {
      es: 'Diseño de packaging lanzamiento Samsung S10+',
      en: 'Packaging design launch Samsung S10 +',
    },
    tags: ['Retail'],
    resources: ['Box-01.png', 'Box-02.png'],
    software: ['Sketchup', 'Layout'],
  },

  {
    thumbnail: 's03.jpg',
    title: 'Diseño de espacio',

    description: 'Samsung Portugal',
    content: () => (
      <Translate>
        Diseño de espacios AV(TV) con nuevas guidelines para tiendas Samsung
        Portugal.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      's01.jpg',
      's02.jpg',
      's03.jpg',
      's04.jpg',
      's05.jpg',
      's06.jpg',
      's07.jpg',
      's08.jpg',
      's09.jpg',
      's10.jpg',
      's11.jpg',
      's12.jpg',
      's13.jpg',
    ],
    software: ['Sketchup', 'Layout'],
  },
  {
    thumbnail: '8k01.jpg',
    title: '8K Samsung',

    description: {
      es: 'Diseño mueble TV 8K Samsung',
      en: 'Furniture design TV 8K Samsung',
    },
    tags: ['Retail'],
    resources: ['8k01.jpg', '8k02.jpg', '8k03.jpg', '8k04.jpg'],
    software: ['Sketchup', 'Layout'],
  },

  {
    thumbnail: 'nue01.jpg',
    title: 'Rediseño tiendas',
    description: 'Samsung Spain',
    content: () => (
      <Translate>
        Diseño de tiendas Samsung zona AV de toda España con nuevas guidelines.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'nue01.jpg',
      'nue02.jpg',
      'nue03.jpg',
      'nue04.jpg',
      'nue05.jpg',
      'nue06.jpg',
      'nue07.jpg',
      'nue08.jpg',
      'nue09.jpg',
      'nue10.jpg',
      'nue11.jpg',
      'nue12.jpg',
      'nue13.jpg',
      'nue14.jpg',
    ],
    software: ['SketchUp', 'V-ray'],
  },
  {
    thumbnail: 'CRF SSRR producto1.jpg',
    title: 'Diseño lineal Carrefour',
    description: 'Samsung Spain',
    content: () => (
      <Translate>
        Diseño y fabricación de nuevos lineales de producto
        WG(electrodomésticos) de Samsung para Carrefour.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'CRF SSRR producto1.jpg',
      'CRF SSRR producto.jpg',

      'CRF SAN FERNANDO DE HENARES 15_04_2019 (1).jpg',
      'CRF SAN FERNANDO DE HENARES 15_04_2019 (2).jpg',
    ],
    software: ['SketchUp', 'V-ray'],
  },
  {
    thumbnail: 'calla01.jpg',
    title: 'El Corte Inglés Callao',
    description: 'Samsung',
    content: () => (
      <Translate>
        Rediseño zona Samsung AV(TV) El Corte Inglés Callao.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'calla01.jpg',
      'callao02.jpg',
      'callao03.jpg',
      'callao04.jpg',
      'callao05.jpg',
      'callao06.jpg',
      'callao07.jpg',
      'callao08.jpg',
      'callao09.jpg',
    ],
    software: ['SketchUp', 'V-ray'],
  },

  {
    thumbnail: 'MM SSRR - montaje Espacio AV (3).jpg',
    title: 'Diseño de espacio Media Markt',

    description: 'Samsung',
    content: () => (
      <Translate>
        Diseño de espacio Samsung AV(TV) con nuevas guidelines en San Sebastián
        de los Reyes.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'MM SSRR - MONTAJE  (1).jpg',
      'MM SSRR - montaje Espacio AV (3).jpg',
      'MMSSRR - VISITA 17052019 (3).jpg',
    ],
    software: ['Sketchup', 'Layout'],
  },
  {
    thumbnail: 'ECI CAMPO DE LAS NACIONES_01.jpg',
    title: 'Diseño de espacio El Corte Inglés',

    description: 'Samsung',
    content: () => (
      <Translate>
        Diseño de espacio de Samsung en Campo de las Naciones para El Corte
        Inglés.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'ECI CAMPO DE LAS NACIONES_01.jpg',
      'ECI CAMPO DE LAS NACIONES_02.jpg',
    ],
    software: ['Sketchup+Vray', 'Layout'],
  },
  {
    thumbnail: 'Display_s10.png',
    title: 'Display para móvil Galaxy S10',

    description: 'Samsung',
    content: () => (
      <Translate>
        Display para phone Samsung Galaxy S10 para Portugal.
      </Translate>
    ),
    tags: ['Retail'],
    resources: ['Display_s10.png', 'Display_s10-03.png', 'Display_s10-02.png'],
    software: ['Sketchup+Vray', 'Layout'],
  },
  {
    thumbnail: 'THE FRAME LINEAL ISLA PROTOTIPO (2).jpg',
    title: 'Display The Frame',

    description: 'Samsung',
    content: () => (
      <Translate>
        Display The Frame, versión para colocar en lineal de islas en tienda.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'THE FRAME LINEAL ISLA PROTOTIPO (2).jpg',
      'THE FRAME LINEAL ISLA PROTOTIPO (3).jpg,',
    ],
    software: ['Sketchup', 'Layout'],
  },

  {
    thumbnail: 'Elgranero_02.jpg',
    title: 'Stand El Granero Alimentaria',
    description: 'Alimentaria',
    content: () => (
      <Translate>
        Stand El Granero para feria de Alimentaria en Barcelona.
      </Translate>
    ),
    tags: ['Retail'],
    resources: [
      'Elgranero_02.jpg',
      'Elgranero_03.png',
      'Elgranero_04.png',
      'Elgranero_05.png',
      'Elgranero_06.png',
      'Elgranero_07.png',
    ],
    software: ['3D Studio Max', 'Autocad'],
  },
  {
    thumbnail: 'ft-01.jpg',
    title: 'Food-truckEmasesa',
    description: {
      es: 'Diseño de Food-truck para Emasesa',
      en: 'Food-truck design for Emasesa',
    },
    tags: ['Diseño gráfico'],
    resources: ['ft-01.jpg', 'ft-02.jpg', 'ft-03.jpg'],
    software: ['Illustrator'],
  },
  {
    thumbnail: 'Valco01.png',
    title: 'Stand Valcoiberia',
    description: {
      es: 'Feria Alimentaria',
      en: 'Alimentaria fair',
    },
    content: () => (
      <Translate>Stand Valcoiberia para Alimentaria Barcelona.</Translate>
    ),
    tags: ['Retail'],
    resources: [
      'Valco01.png',
      'Valco02.png',
      'Valco03.png',
      'Valco04.png',
      'Valco05.png',
      'Valcoiberia02.jpg',
      'Valcoiberia04.jpg',
    ],
    software: ['Sketchup+Vray', 'Autocad'],
  },
  {
    thumbnail: 'Elgranerovarios.jpg',
    title: 'Stand El granero',
    description: {
      es: 'Feria A Coruña',
      en: 'A Coruña fair',
    },
    content: () => (
      <Translate>Stand El Granero para feria en A Coruña.</Translate>
    ),
    tags: ['Retail'],
    resources: ['Elgranerovarios.jpg', 'Elgranerovarios02.jpg'],
    software: ['3D Studio Max', 'Autocad'],
  },

  {
    thumbnail: 'camera.gif',
    title: 'Smile',
    description: {
      es: 'Animación de cámara vintage',
      en: 'Vintage camera animation',
    },
    tags: ['Animación', 'Ilustración'],
    resources: ['camera.gif', 'camara01_mesa-de-trabajo-1.png'],
    software: ['Illustrator', 'After Effects'],
  },

  {
    thumbnail: 'alex-11.jpg',
    title: 'Creepyface',
    description: {
      es: 'Ilustración',
      en: 'Illustration',
    },
    content: () => (
      <Translate>
        Ilustración vectorial para demostración librería JavaScript.
      </Translate>
    ),
    tags: ['Diseño gráfico', 'Ilustración'],
    software: ['Illustrator'],
  },

  {
    thumbnail: 'Granero01.jpg',
    title: 'EcoCesta',
    description: {
      es: 'Stand para feria Alimentaria en Barcelona',
      en: 'Stand for Alimentaria fair in Barcelona',
    },
    tags: ['Retail'],
    resources: [
      'Granero01.jpg',
      'Granero02.jpg',
      'Granero03.jpg',
      'Granero04.jpg',
    ],
    software: ['SketchUp', 'V-ray'],
  },
  {
    thumbnail: 'guitar-02-01.jpg',
    title: 'Icono App',
    description: {
      es: 'Icono para aplicación de guitarra',
      en: 'Icon for guitar app',
    },
    content: () => (
      <a
        href="https://react-guitar.com"
        className="no-underline hover:underline text-teal-400"
      >
        <strong>React guitar</strong>
      </a>
    ),

    tags: ['Diseño gráfico', 'Ilustración'],
    resources: ['guitar-01.jpg', 'guitar-02-01.jpg'],
    software: ['Illustrator'],
  },
  {
    thumbnail: 'zorro.jpg',
    title: 'Fox',
    description: {
      es: 'Ilustración en negativo de un zorro',
      en: ' Negative illustration of a fox',
    },
    tags: ['Ilustración'],
    resources: ['zorro.jpg', 'zorro3-01-e1490815826680.jpg', 'zorro1-01.jpg'],
    software: ['Illustrator'],
  },

  {
    thumbnail: '2-07.jpg',
    title: 'Packaging Chocolate',
    description: {
      es: 'Composición realizada para concurso, marca de chocolate',
      en: 'Composition made for contest, chocolate brand',
    },
    tags: ['Diseño gráfico'],
    software: ['Photoshop'],
  },
  {
    thumbnail: 'jirafa.jpg',
    title: 'Jirafa',
    description: {
      es: 'Ilustración de jirafa indie',
      en: 'Indie giraffe illustration',
    },
    resources: ['jirafa.jpg'],
    tags: ['Ilustración'],
  },
  {
    thumbnail: 'elreydelasranas1.jpg',
    title: 'El Rey de las Ranas',
    description: {
      es: 'Portada para el libro El Rey de las Ranas',
      en: 'Cover for the book The King of the Frogs',
    },
    client: {
      name: 'Worlreader',
    },
    tags: ['Diseño gráfico', 'Ilustración'],
    software: ['Illustrator'],
  },

  {
    thumbnail: 'hazlofuniconar.jpg',
    title: 'Web Hazlofuncionar',
    description: {
      es: 'Diseño de la web hazlofuncionar.com',
      en: 'Web design hazlofuncionar.com ',
    },
    tags: ['Diseño gráfico', 'web'],
    resources: ['hazlofuniconar_ok.jpg', 'handmade.jpg'],
    software: ['Wordpress'],
  },
  {
    thumbnail: 'tucan.jpg',
    title: 'Tucán',
    description: {
      es: 'Ilustración multitécnica de un tucán',
      en: 'Multi-technical illustration of a toucan',
    },
    tags: ['Ilustración'],
  },
  {
    thumbnail: 'wolf3.jpg',
    title: 'Wolf-Flow',
    description: {
      es: 'Ilustraciones a bolígrafo y acuarela',
      en: 'Pen and watercolor illustrations',
    },
    tags: ['Ilustración'],
    resources: ['wolf5-01.jpg', 'wolf3.jpg'],
  },
  {
    thumbnail: 'pajaros3-01.jpg',
    title: 'Pattern primavera',
    description: {
      es: 'Trabajo experimental propio',
      en: 'Own experimental work',
    },
    tags: ['Diseño gráfico'],
    software: ['Photoshop'],
  },
  {
    thumbnail: 'patterncupcake.jpg',
    title: 'Pattern',
    description: {
      es: 'Patrón para cliente Bollitos & Kukis',
      en: 'Pattern for client Bollitos & Kukis',
    },
    client: {
      name: 'Bollitos & Kukis',
    },
    tags: ['Diseño gráfico'],
    software: ['Illustrator'],
  },

  {
    thumbnail: 'buho.png',
    title: 'Buho',
    description: {
      es: 'Ilustración utilizando la técnica rotring',
      en: 'Illustration using the rotring technique',
    },
    tags: ['Ilustración'],
  },
  {
    thumbnail: 'cabeza.png',
    title: 'Intruso',
    description: {
      es: 'Ilustración a bolígrafo digitalizada',
      en: 'Digitized pen illustration',
    },
    tags: ['Ilustración'],
  },
  {
    thumbnail: 'minino22.png',
    title: 'Minino',
    description: {
      es: 'Ilustración a lápiz digitalizada',
      en: 'Digitized pencil illustration',
    },
    tags: ['Ilustración'],
    resources: ['minino22.png', 'minino.jpg'],
    software: ['Illustrator'],
  },

  {
    thumbnail: 'micro.jpg',
    title: 'Microvídeos Angaraveca',
    description: {
      es: 'Edición del vídeo corporativo de Angaraveca',
      en: 'Editing of the corporate video of Angaraveca',
    },
    client: { name: 'Angaraveca', url: 'http://www.angaraveca.com' },
    tags: ['Animación'],
    resources: [
      'https://player.vimeo.com/video/84315951',
      'https://player.vimeo.com/video/84315952',
      'https://player.vimeo.com/video/84315953',
      'https://player.vimeo.com/video/84316116',
      'https://player.vimeo.com/video/84315587',
      'https://player.vimeo.com/video/84315586',
    ],
    software: ['After Effects'],
  },
  {
    thumbnail: 'yosytu.jpg',
    title: 'Yo soy tú',
    description: {
      es: 'Video presentación para el libro "Yo soy tú"',
      en: 'Video presentation for the book "Yo soy tú"',
    },
    tags: ['Animación'],
    client: {
      name: 'Diego Isabel La Moneda',
    },
    content: () => (
      <>
        <p className="mb-2">
          <Translate>Video presentación para el libro</Translate>{' '}
          <i>
            <Translate>
              ”Yo soy tú, propuesta para una nueva sociedad”
            </Translate>
          </i>{' '}
          Diego Isabel La Moneda.
        </p>
        <ul className="mb-2">
          <li>
            <b>
              <Translate>Cámara y montaje</Translate>:
            </b>{' '}
            Carlos Isabel La Moneda.
          </li>
          <li>
            <b>
              <Translate>Personajes y Postproducción</Translate>:
            </b>{' '}
            Luis Madrid.
          </li>
          <li>
            <b>
              <Translate>Escenarios</Translate>:
            </b>{' '}
            Noemí Villegas
          </li>
        </ul>
      </>
    ),
    resources: ['https://www.youtube.com/embed/Uh043IaXGuI'],
    software: ['After Effects'],
  },
]

export default projects
